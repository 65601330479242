<template>
  <iframe v-if="id" width="980" height="550" :src="link" allow="encrypted-media; autoplay" allowfullscreen/>
</template>

<script>
import ModalBlockPropsMixin from '@/components/blocks/modals/ModalBlockPropsMixin.js'

export default {
	name: 'VimeoModal',
	mixins: [ModalBlockPropsMixin],
	computed: {
		link() {
			return `https://player.vimeo.com/video/${this.id}?h=0c1687ac06&autoplay=1`
		}
	}
}
</script>