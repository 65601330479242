export const navigation = {
	state: {
		activeIndex: null,
		main: [],
		scrollDownRoute: null
	},
	mutations: {
		updateActiveMenuIndex(state, index) {
			if(index >= 0) state.activeIndex = index
		},
		updateMainNavigation(state, links) {
			state.main = links
		},
		updateScrollDownRoute(state, route) {
			state.scrollDownRoute = route
		},
	},
	actions: {
		updateActiveMenuIndex(context, index) {
			context.commit('updateActiveMenuIndex', index)
		},
		updateMainNavigation(context, links) {
			context.commit('updateMainNavigation', links)
		},
		updateScrollDownRoute(context, route) {
			context.commit('updateScrollDownRoute', route)
		}
	}
}