import {ROUTES} from '@/helpers/index.js'

export const home = {
	state: {
		sections: null
	},
	mutations: {
		updateHomeSections(state, sections) {
			state.sections = sections
		}
	},
	actions: {
		async updateHomeSections(context, sections) {
			let menuIndex = -1
			const menuAnchors = []

			const formattedSections = sections.map((section, sectionIndex) => {
				const hasMenuAnchor = section?.anchors.length

				if (hasMenuAnchor) {
					menuAnchors.push({
						label: section.anchors[0].name,
						route: {name: ROUTES.HOME, hash: `#${section.anchors[0].slug}`}
					})
					menuIndex += 1
				}

				const menuAnchor = hasMenuAnchor ? section.anchors[0].slug : `section-${section.flexKey}`

				if (sectionIndex === 1) {
					context.dispatch('updateScrollDownRoute', {name: ROUTES.HOME, hash: `#${menuAnchor}`})
				}

				return {
					...section,
					id: menuAnchor,
					menuIndex: hasMenuAnchor ? menuIndex : -1
				}
			})

			context.commit('updateHomeSections', formattedSections)

			await context.dispatch('updateMainNavigation', menuAnchors)
		}
	}
}