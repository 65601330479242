import { createStore } from 'vuex'

import {app} from '@/store/app'
import {home} from '@/store/home'
import {navigation} from '@/store/navigation'

export default createStore({
	modules: {
		app,
		home,
		navigation
	}
})
