<template>
  <button class="modal__button">
    <span v-for="i in 2" :key="i"/>
  </button>
</template>

<script>
export default {
	name: 'ModalButton'
}
</script>