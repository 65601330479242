export default {
	banner: {
		accept: 'Accept',
		description: 'We use cookies and other tracking technologies to improve your browsing experience on our website, to analyze our website traffic and to understand where our visitors come from.',
		more: 'Learn More',
		settings: 'Manage Settings',
		title: 'Our use of cookies',
	},
	settings: {
		analytics: {
			description: 'Google Analytics cookies enable us to improve our website by collecting and reporting information about how you use it. These cookies collect information with total anonymity.',
			title: 'Analytics cookies'
		},
		description: 'When you visit this website, it may store or retrieve information on your browser, mainly in the form of cookies. This information does not identify you directly, but it is used to facilitate navigation and to provide a more personalized experience. Because we respect your right to privacy, you can choose not to allow certain types of cookies by modifying the default settings.',
		privacy: {
			description: 'For more information on how cookies work, please consult our',
			label: 'Terms and conditions'
		},
		save: 'Save and Close',
		title: 'How We Use Cookies'
	},
}