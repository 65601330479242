<template>
  <div v-if="id" ref="calendar" class="modal__calendly"/>
</template>

<script>
import ModalBlockPropsMixin from '@/components/blocks/modals/ModalBlockPropsMixin.js'

export default {
	name: 'CalendlyModal',
	mixins: [ModalBlockPropsMixin],
	watch: {
		'id': {
			handler() {
				if (this.id) {
					this.$nextTick(() => {
						//CDN plugin
						Calendly.initInlineWidget({
							url: `https://calendly.com/${this.id}`,
							parentElement: this.$refs.calendar,
							prefill: {},
							utm: {}
						})
					})
				}
			},
			immediate: true
		}
	}
}
</script>