<template>
  <div v-if="$beet.isReady">
    <SiteNav/>
    <Modal/>
    <RouterView v-slot="{ Component }">
      <Transition name="fade-fast" mode="out-in">
        <Component :is="Component"/>
      </Transition>
    </RouterView>
    <Gdpr :options="{backgroundColor: '#F8E3EC', color: '#C51262'}"/>
  </div>
  <Transition name="fade-fast">
    <Loader v-if="!$beet.isReady"/>
  </Transition>
</template>

<script>
import Gdpr from '@/components/gdpr/Gdpr.vue'
import {SiteNav} from '@/components/navigation'
import {Modal} from '@/components/sections'
import {Loader} from '@/components/small'
import {useLangRouter} from '@/extensions/langRouter'

export default {
	name: 'App',
	components: {Gdpr, Loader, Modal, SiteNav},
	setup() {
		useLangRouter()
	},
	watch: {
		'$i18n.locale': {
			handler: function (to) {
				this.$beet.fetchCoreData(to)
			},
			immediate: true
		},
		'$route.name'() {
			this.$store.dispatch('updateActiveMenuIndex', null)
		}
	},
	updated() {
		this.$nextTick(() => {
			const el = this.$route.hash ? document.querySelector(this.$route.hash) : null
			el ?
				el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
				window.scrollTo({top: 0, behavior: 'smooth'})
		})
	}
}
</script>
