<template>
  <nav :class="['navbar', {'--opened': opened}]">
    <div class="navbar__top v-reveal" v-reveal="{delay: 1200}">
      <LangRouterLink :to="{name: ROUTES.HOME}" class="navbar__top-left">
        <img src="@/assets/images/logo.svg" alt="" class="navbar__logo"/>
        <template v-if="$store.state.app.title">
          <span class="navbar__top-line"/>
          <p class="navbar__top-title">{{ $store.state.app.title }}</p>
        </template>
      </LangRouterLink>
      <div v-if="!mq.mdPlus" class="navbar__top-right">
        <button @click="$store.dispatch('updateCalendlyId', $beet.options.contact.expert.id)"
                class="navbar__top-contact">
          <Icon id="contact"/>
        </button>
        <button :class="['navbar__hamburger', {'--opened': opened}]" @click="opened = !opened">
          <span v-for="i in 3" :key="i"/>
        </button>
      </div>
      <div v-if="mq.mdPlus" class="navbar__top-right">
        <ContactButton/>
        <LangSwitch/>
      </div>
    </div>
    <div class="navbar__bottom" v-if="!mq.mdPlus">
      <ul class="navbar__bottom-list">
        <li v-for="(link, linkIndex) in $store.state.navigation.main" :key="linkIndex">
          <LangRouterLink :to="link.route" class="navbar__bottom-link" @click="opened = false">
            <span :class="['navbar__bottom-label', {'--active': $store.state.navigation.activeIndex === linkIndex}]">{{ link.label }}</span>
          </LangRouterLink>
        </li>
      </ul>
      <div class="navbar__bottom-buttons">
        <ContactButton/>
        <LangSwitch/>
      </div>
    </div>
  </nav>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/global'
import {LangSwitch} from '@/components/navigation'
import {ContactButton} from '@/components/small'
import {bodyHelpers} from '@/helpers'
import {UsesRoutesMixin} from '@/mixins'

export default {
	name: 'SiteNav',
	inject: ['mq'],
	mixins: [UsesRoutesMixin],
	components: {ContactButton, Icon, LangRouterLink, LangSwitch},
	data() {
		return {
			opened: false
		}
	},
	methods: {
		closeMobileMenu() {
			if(this.mq.mdPlus) this.opened = false
		}
	},
	watch: {
		'opened'() {
			this.opened ? bodyHelpers.lock() : bodyHelpers.unlock()
		}
	},
	mounted() {
		window.addEventListener('resize', this.closeMobileMenu)
	},
	unmounted() {
		window.removeEventListener('resize', this.closeMobileMenu)
	}
}
</script>
