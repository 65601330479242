export default {
	banner: {
		accept: 'Accepter',
		description: 'Nous utilisons des cookies et d’autres technologies de suivi pour améliorer votre expérience de navigation sur notre site Web, pour analyser le trafic de notre site Web et pour comprendre d’où viennent nos visiteurs.',
		more: 'Obtenir plus d\'informations',
		settings: 'Gérer les préférences',
		title: 'Notre utilisation des témoins (cookies)'
	},
	settings: {
		analytics: {
			description: 'Les fichiers témoins Google Analytics nous permettent d’améliorer notre site Web en collectant et en rapportant des informations sur la façon dont vous l\'utilisez. Les fichiers témoins collectent des informations de manière complètement anonyme.',
			title: 'Fichiers de témoins d’analyse'
		},
		description: 'Lorsque vous visitez ce site, celui-ci peut stocker ou récupérer des informations sur votre navigateur, principalement sous forme de témoins (cookies). Ces informations ne permettent pas de vous identifier directement, elles sont utilisées pour faciliter la navigation et offrir une expérience plus personnalisée. Parce que nous respectons votre droit à la vie privée, vous pouvez choisir de ne pas autoriser certains types de témoins en modifiant les paramètres définis par défaut.',
		privacy: {
			description: 'Pour plus d\'informations sur les témoins utilisés, consultez notre page',
			label: 'Termes et conditions'
		},
		save: 'Enregistrer et fermer',
		title: 'Notre utilisation des témoins (cookies)'
	}
}