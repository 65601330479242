import {createRouter, createWebHistory} from 'vue-router'
import {generateRoutes} from '@/extensions/langRouter'
import {DROPDOWN_ID, ROUTES} from '@/helpers'

const Home = () => import('@/views/Home.vue')

const routes = [
	{
		path: '/',
		name: ROUTES.HOME,
		component: Home
	},
	{
		path: '/conditions-dutilisation',
		paths: {en: '/terms-of-use'},
		name: ROUTES.TERMS,
		component: () => import('@/views/Terms.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'catch-all',
		component: Home
	}
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

export default createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	linkActiveClass: '-active',
	linkExactActiveClass: '-exact-active',
	routes: localizedRoutes,
	scrollBehavior(to, from) {
		if (to.hash) {
			const hashResolveObject = {el: to.hash, behavior: 'smooth', top: 75, left: 65}

			if(to.hash.includes(DROPDOWN_ID)) {
				return new Promise(resolve => setTimeout(() => resolve(hashResolveObject), 450))
			}

			return hashResolveObject
		}

		if (to.name === from.name) return {top: 0, behavior: 'smooth'}

		return new Promise(resolve => setTimeout(() => resolve({top: 0}), 200))
	}
})