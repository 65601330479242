<template>
  <Transition name="fade">
    <aside v-if="opened" class="modal" @click="close">
      <ModalButton @click="close"/>
      <CalendlyModal :id="$store.state.app.calendly_id" @click.stop/>
      <VimeoModal :id="$store.state.app.vimeo_id" @click.stop/>
    </aside>
  </Transition>
</template>

<script>
import {CalendlyModal, VimeoModal} from '@/components/blocks/modals'
import {ModalButton} from '@/components/small'
import {bodyHelpers} from '@/helpers'

export default {
	name: 'Modal',
	components: {CalendlyModal, ModalButton, VimeoModal},
	computed: {
		opened() {
			return this.$store.state.app.calendly_id || this.$store.state.app.vimeo_id
		}
	},
	methods: {
		close() {
			if(this.$store.state.app.calendly_id) this.$store.dispatch('updateCalendlyId')
			else if(this.$store.state.app.vimeo_id) this.$store.dispatch('updateVimeoId')
		}
	},
	watch: {
		'opened'() {
			this.opened ? bodyHelpers.lock() : bodyHelpers.unlock()
		}
	}
}
</script>