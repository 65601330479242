export const app = {
	state: {
		calendly_id: null,
		title: null,
		vimeo_id: null
	},
	mutations: {
		updateCalendlyId(state, id) {
			state.calendly_id = id
		},
		updateSiteTitle(state, title) {
			state.title = title
		},
		updateVimeoId(state, id) {
			state.vimeo_id = id
		}
	},
	actions: {
		updateCalendlyId(context, id = null) {
			context.commit('updateCalendlyId', id)
		},
		updateSiteTitle(context, title) {
			context.commit('updateSiteTitle', title)
		},
		updateVimeoId(context, id = null) {
			context.commit('updateVimeoId', id)
		}
	}
}