<template>
  <button class="button" @click="$store.dispatch('updateCalendlyId', $beet.options.contact.expert.id)">
    <Icon v-if="!mq.mdPlus" id="contact"/>
    <span>{{ $beet.options.contact.expert.label }}</span>
  </button>
</template>

<script>
import {Icon} from '@/components/global/index.js'

export default {
	name: 'ContactButton',
	inject: ['mq'],
	components: {Icon}
}
</script>