<template>
  <p class="gdpr-settings__privacy">
    <span>{{ `${$t('gdpr.settings.privacy.description')} ` }}</span>
    <LangRouterLink :to="{name: ROUTES.TERMS}" class="gdpr-settings__privacy-link">
      {{ $t('gdpr.settings.privacy.label') }}
    </LangRouterLink>
    <span>.</span>
  </p>
</template>

<script>
import {LangRouterLink} from '@/components/global'
import {UsesRoutesMixin} from '@/mixins'

export default {
	name: 'GdprPrivacy',
	mixins: [UsesRoutesMixin],
	components: {LangRouterLink}
}
</script>