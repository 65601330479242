<template>
  <button class="navbar__langSwitch" @click="$i18n.locale = toLang">
     {{ toLang }}
  </button>
</template>

<script>
export default {
	name: 'LangSwitch',
	computed: {
		toLang() {
			return this.$i18n.availableLocales.filter(locale => locale !== this.$i18n.locale)[0]
		}
	}
}
</script>